<template>
  <div class="race-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      :numBers="currentStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <div class="timer">
        <div class="clock_cont">
          <div
            ref="clock"
            class="clock"
            :style="{ width: timerPercentage + '%' }"
          ></div>
        </div>
      </div>
      <div class="turtleWrapper">
        <img
          class="time"
          :src="turtle"
          :style="{ right: timerPercentage + '%' }"
        />
      </div>

      <div class="path">
        <div class="objects">
          <div class="topCardWrapper">
            <div
              class="cards"
              v-for="(item, index) in topObjectList"
              :key="index"
            >
              <div
                class="imageCard"
                v-if="
                  item.image &&
                  item.id <= stepsList[currentStep].index + 1 &&
                  item.canPass == false
                "
                :style="{ transform: 'scale(1.5)' }"
              >
                <img class="picture" :src="item.image" />
              </div>
            </div>
          </div>
          <div class="bottomCardWrapper">
            <div
              class="cards"
              v-for="(item, index) in elementOptionList"
              :key="index"
            >
              <div
                :class="{
                  blockingCard: item.image != '',
                  blueBgColor: !item.isVisible,
                }"
                class="wordCard"
              >
                <span class="font-pinyin-medium pinyin">{{ item.pinying }}</span>
                <span class="font-hanzi-medium">{{ item.hanzi }}</span>
              </div>
            </div>
          </div>
        </div>
        <img class="ground" :src="path" />
      </div>

      <div class="rabbitWrapper">
        <img
          class="time"
          :src="rabbit"
          :style="{ right: rabbitPostion + '%' }"
        />
      </div>

      <div class="bottonCont">
        <div class="redBackground"></div>
        <div class="bottonsWrapper">
          <div
            v-for="(item, index) in answerList"
            :key="index"
            class="content-words-two"
            @click="foundAnswer(item.data)"
          >
            <span class="font-english-large">{{ item.data }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  props: {
    answerList: {
      type: Array,
      require: true,
    },
    elementOptionList: {
      type: Array,
      require: true,
    },
  },
  components: {
    PageButton,
  },
  data() {
    return {
      totalTime: 25,
      timeLeft: 25,
      timerPercentage: 100,
      rabbitPostion: 100,
      isLastStep: false,

      turtle: require("@/assets/img/16-GAMES/G40-race/turtle.svg"),
      rabbit: require("@/assets/img/16-GAMES/G40-race/rabbit.svg"),
      path: require("@/assets/img/16-GAMES/G40-race/path.svg"),

      totalSteps: 0,
      currentStep: 0,

      stepsList: [],

      topObjectList: [
        {
          id: 1,
          image: "",
          canPass: true,
          data: "",
        },
        {
          id: 2,
          image: "",
          canPass: true,
          data: "",
        },
        {
          id: 3,
          image: "",
          canPass: true,
          data: "",
        },
        {
          id: 4,
          image: "",
          canPass: true,
          data: "",
        },
        {
          id: 5,
          image: "",
          canPass: true,
          data: "",
        },
        {
          id: 6,
          image: "",
          canPass: true,
          data: "",
        },
        {
          id: 7,
          image: "",
          canPass: true,
          data: "",
        },
        {
          id: 8,
          image: "",
          canPass: true,
          data: "",
        },
        {
          id: 9,
          image: "",
          canPass: true,
          data: "",
        },
        {
          id: 10,
          image: "",
          canPass: true,
          data: "",
        },
      ],
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 0,
          },
        ],
      },
      raceSocketInfo: {},
    };
  },
  mounted() {
    this.runTimer();
    this.initData();
    this.$bus.$on("clickRaceGame", ({ data }) => {
      this.foundAnswer(data, true);
    });
  },
  beforeDestroy(){
    this.$bus.$off("clickRaceGame")
  },
  watch: {
    raceSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20032,
          data: { value },
          text: "raceGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    foundAnswer(data, isFromSocket = false) {
      if (!isFromSocket) {
        this.raceSocketInfo = {
          data,
          value: Math.random(),
        };
      }
      if (
        data ==
          this.topObjectList[this.stepsList[this.currentStep].index].data &&
        this.currentStep < this.totalSteps
      ) {
        // correct answer
        this.topObjectList[
          this.stepsList[this.currentStep].index
        ].canPass = true;

        // add a "fadeOut" to the correct card
        this.topObjectList[
          this.stepsList[this.currentStep].index
        ].isVisible = false;

        if (this.currentStep == 5) {
          clearTimeout(this.timer); // stop the turtle if correct
          playCorrectSound();
          startConfetti();
          this.isLastStep = true;
        }

        if (this.currentStep < 5) {
          this.currentStep++;
          this.rabbitPostion =
            100 - this.stepsList[this.currentStep].index * 8.5;
        } else {
          this.rabbitPostion = 10;
        }
        playCorrectSound(true, false);
        console.log("herere");
      } else {
        // if wrong
        playCorrectSound(false);
      }
    },
    runTimer() {
      if (this.timeLeft < 1) {
        console.log("time is up");
        return;
      } else {
        this.timer = setTimeout(() => {
          this.timeLeft--;
          //  document.getElementById("clock").style.height = toString(this.calculateTimer(this.timeLeft)) + "%";
          this.timerPercentage = this.calculateTimer(this.timeLeft);
          // console.log(this.calculateTimer(this.timeLeft))
          this.runTimer();
        }, 1000);
      }
    },

    calculateTimer(TimeLeft) {
      var percent;
      percent = (TimeLeft / this.totalTime) * 100;

      return percent;
    },
    initData() {
      //var stepIndex = 0
      for (let index = 0; index < this.elementOptionList.length; index++) {
        if (this.elementOptionList[index].canPass == false) {
          this.topObjectList[index] = this.elementOptionList[index];
          this.stepsList.push({ index: index });
        }
      }

      this.totalSteps = this.stepsList.length;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>
<style lang="scss" scoped>
.path {
  top: 48%;
  position: absolute;
  width: 78%;
  left: 10%;
  .objects {
    .cards {
      height: 100%;
      //  background-color: black;
      width: 9%;
      .imageCard {
        background-color: #ce524c;
        border-radius: 30%;
        height: 100%;
        .picture {
          height: 85%;
        }
      }
      .wordCard {
        background-color: #224e96;
        border-radius: 10px;
        // height: 100%;
        padding: 8px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        color: #fff;

        &.blockingCard {
          background-color: #ce524c;
        }
      }
    }
    .topCardWrapper {
      width: 90%;
      right: 0;
      //  background-color: blue;
      position: absolute;
      height: 10vh;
      top: -10vh;
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-content: space-evenly;
      align-items: center;
    }
    .bottomCardWrapper {
      width: 90%;
      right: 0;
      // background-color: blue;
      position: absolute;
      height: 10vh;
      top: 6vh;
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-content: space-evenly;
      align-items: center;
    }
  }
  .ground {
    width: 100%;
  }
}
.turtleWrapper {
  position: absolute;
  top: 15%;
  height: 5%;
  width: 84%;
  border-radius: 100px;
  right: 3%;
  .time {
    right: 100%;
    height: 6vh;
    top: -6vh;
    position: absolute;
    transition-delay: 0.1s;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }
}
.rabbitWrapper {
  position: absolute;
  top: 50%;
  height: 5%;
  width: 81%;
  border-radius: 100px;
  right: 3%;
  img {
    transition-delay: 0.1s;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }
  .time {
    right: 99%;
    height: 15vh;
    top: -15vh;
    position: absolute;
  }
}
.timer {
  position: absolute;
  top: 15%;
  height: 5%;
  width: 80%;
  border-radius: 100px;
  left: 10%;
  background-color: #cfc8b0;
  overflow: hidden;
  .clock_cont {
    height: 100%;
    position: absolute;
    //  left: 31%;
    width: 100%;
    /* margin-left: -3px; */
    // top: 4%;
    .clock {
      background-color: #f4d737;
      height: 100%;
      position: absolute;
      right: 0;
      width: 100%;
      /* margin-left: -3px; */
      bottom: 0;
      transition-delay: 0.1s;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
    }
  }
}

.bottonCont {
  bottom: 0;
  width: 80%;
  margin-bottom: 32px;
  height: 20%;
  position: absolute;
  display: flex;
  justify-content: center;
  left: 10%;
  .redBackground {
    width: 100%;
    height: 50%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #d87065;
    position: absolute;
    bottom: 0;
  }
  .bottonsWrapper {
    display: flex;
    bottom: 0;
    height: -moz-fit-content;
    justify-content: center;
    height: 100%;
    width: 60%;
    padding: 0 20px;
    position: absolute;

    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #ffffff;
    justify-content: space-evenly;
    align-items: center;
  }
}

.content-words-two {
  width: 12%;
  // height: 70%;
  z-index: 1;
  border-radius: 11px;
  padding: 15px 0;
  background-color: #cd4c3f;
  border-bottom: 8px solid #ac3d32;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    color: #fff;
    font-size: 25px;
  }
  span:nth-child(2) {
    color: #fff;
    font-size: 30px;
  }
}
.content-words-two:active {
  transform: scale(0.95);
}
.content-words-two:hover {
  width: 12%;
  // height: 70%;
  border-radius: 11px;
  padding: 15px 0;
  background-color: #912016;
  border-top: 8px solid #6e0c03;
  border-bottom: 0px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  span {
    color: #fff;
    font-size: 25px;
  }
  span:nth-child(2) {
    color: #fff;
    font-size: 30px;
  }
}

.race-game-container {
  background: #fff;
  border-radius: 48px;
  position: relative;
  overflow: hidden;
}
.game-content {
  width: 100%;
  height: 100%;
  text-align: -webkit-center;
  background: #f9f6e6;
  border-radius: 48px;
  margin: auto;
}

.blueBgColor {
  background: #224e96 !important;
}
</style>
