var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "race-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
          numBers: _vm.currentStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c("div", { staticClass: "game-content" }, [
        _c("div", { staticClass: "timer" }, [
          _c("div", { staticClass: "clock_cont" }, [
            _c("div", {
              ref: "clock",
              staticClass: "clock",
              style: { width: _vm.timerPercentage + "%" },
            }),
          ]),
        ]),
        _c("div", { staticClass: "turtleWrapper" }, [
          _c("img", {
            staticClass: "time",
            style: { right: _vm.timerPercentage + "%" },
            attrs: { src: _vm.turtle },
          }),
        ]),
        _c("div", { staticClass: "path" }, [
          _c("div", { staticClass: "objects" }, [
            _c(
              "div",
              { staticClass: "topCardWrapper" },
              _vm._l(_vm.topObjectList, function (item, index) {
                return _c("div", { key: index, staticClass: "cards" }, [
                  item.image &&
                  item.id <= _vm.stepsList[_vm.currentStep].index + 1 &&
                  item.canPass == false
                    ? _c(
                        "div",
                        {
                          staticClass: "imageCard",
                          style: { transform: "scale(1.5)" },
                        },
                        [
                          _c("img", {
                            staticClass: "picture",
                            attrs: { src: item.image },
                          }),
                        ]
                      )
                    : _vm._e(),
                ])
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "bottomCardWrapper" },
              _vm._l(_vm.elementOptionList, function (item, index) {
                return _c("div", { key: index, staticClass: "cards" }, [
                  _c(
                    "div",
                    {
                      staticClass: "wordCard",
                      class: {
                        blockingCard: item.image != "",
                        blueBgColor: !item.isVisible,
                      },
                    },
                    [
                      _c("span", { staticClass: "font-pinyin-medium pinyin" }, [
                        _vm._v(_vm._s(item.pinying)),
                      ]),
                      _c("span", { staticClass: "font-hanzi-medium" }, [
                        _vm._v(_vm._s(item.hanzi)),
                      ]),
                    ]
                  ),
                ])
              }),
              0
            ),
          ]),
          _c("img", { staticClass: "ground", attrs: { src: _vm.path } }),
        ]),
        _c("div", { staticClass: "rabbitWrapper" }, [
          _c("img", {
            staticClass: "time",
            style: { right: _vm.rabbitPostion + "%" },
            attrs: { src: _vm.rabbit },
          }),
        ]),
        _c("div", { staticClass: "bottonCont" }, [
          _c("div", { staticClass: "redBackground" }),
          _c(
            "div",
            { staticClass: "bottonsWrapper" },
            _vm._l(_vm.answerList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "content-words-two",
                  on: {
                    click: function ($event) {
                      return _vm.foundAnswer(item.data)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "font-english-large" }, [
                    _vm._v(_vm._s(item.data)),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }